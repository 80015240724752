import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { HOME } from '@/referentials/routes';

import Layout from '@/components/Layout';
import LinkItem from '@/components/LinkItem';

export default function NotFound({ data }) {
  const { t } = useTranslation();

  return (
    <Layout description="404">
      <div className="mt-[150px] lg:mt-[200px] max-w-main lg:mx-auto">
        <div className="font-heading text-[60px]">404</div>
        <div className="text-xl lg:text-3xl font-bold">{t('title')}</div>
        <GatsbyImage
          image={getImage(data.notFound)}
          alt="not found"
          className="my-[50px] border-black border-[5px] rounded-[50px]"
          imgClassName="rounded-[50px]"
        />
        <LinkItem
          title={t('link')}
          to={HOME}
          className="font-heading text-xl lg:text-3xl"
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["404", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    notFound: file(relativePath: { eq: "404.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
